<template>
  <article class="a-edit-content">
    <slot />
  </article>
</template>

<script>
export default {
  name: "a-edit-content",
};
</script>

<style lang="scss">
@use "style";
</style>