import { createApp } from "vue/dist/vue.esm-bundler";
import dayjs from "dayjs";
import VueSmoothScroll from 'vue3-smooth-scroll'
import components from "./components";
import { fetchStaffs, fetchBanners, fetchCategories, fetchRankingBlogs } from "@methods/fetch";
import { getLocalStorage, setLocalStorage } from "@methods/localStorage";

const App = {
  data() {
    return {
      latestModified: window.latestModified || "",
      siteUrl: window.siteUrl || "",
      apiUrl: window.apiUrl || "",
      themeUrl: window.themeUrl || "",
      imageUrl: window.imageUrl || "",
      contactUrl: "https://docs.google.com/forms/d/e/1FAIpQLSeI917tmuqhv3AAkcAhG6DmIpPLjvgKRjJiLg_lRMklT0Or2g/viewform",
      breadcrumbs: window.breadcrumbs || [],
      staffs: [],
      banners: [],
      categories: [],
      rankingBlogs: [],
      tableOfContents: [],
    }
  },
  components: components,
  mixins: window.pageMixins || [],
  created() {
    this.isNewDataLoad()
      ? this.fetchSideNavData()
      : this.getLocalSideNavData();

    // TODO: 後で処理を見直す
    // - 以下はキャッシュをしないので、どうすべきが見直す
    fetchRankingBlogs((rankingBlogs) => {
      this.rankingBlogs = rankingBlogs;
      setLocalStorage('rankingBlogs', this.rankingBlogs);
    });
  },
  methods: {
    fetchSideNavData() {
      setLocalStorage('fetch-date', dayjs().format());

      fetchStaffs((staffs) => {
        this.staffs = staffs;
        setLocalStorage('staffs', this.staffs);
      });
      fetchBanners((banners) => {
        this.banners = banners;
        setLocalStorage('banners', this.banners);
      });
      fetchCategories((categories) => {
        this.categories = categories;
        setLocalStorage('categories', this.categories);
      });
    },
    getLocalSideNavData() {
      this.staffs = this.shuffleArray(getLocalStorage('staffs'));
      this.banners = getLocalStorage('banners');
      this.categories = getLocalStorage('categories');
    },
    getThemeUrl(path) {
      return `${this.themeUrl}${path}`
    },
    formatDate(date) {
      return dayjs(date).format('YYYY.MM.DD');
    },
    isNewDataLoad() {
      if (!getLocalStorage('fetch-date')) return true;

      const previousDataGetDate = dayjs(getLocalStorage('fetch-date'));
      const latestModifiedDate = dayjs(this.latestModified);

      return latestModifiedDate.isAfter(previousDataGetDate);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  }
};

createApp(App).use(VueSmoothScroll).mount("#vue-app");
