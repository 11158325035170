// Atoms
import VcButton from "./vue/button/index.vue";
import AButton from "./Atoms/AButton/index.vue";
import AArticleSmall from "./Atoms/AArticleSmall/index.vue";
import AStaff from "./Atoms/AStaff/index.vue";
import AArticle from "./Atoms/AArticle/index.vue";
import ACompany from "./Atoms/ACompany/index.vue";
import APagination from "./Atoms/APagination/index.vue";
import ABreadcrumb from "./Atoms/ABreadcrumb/index.vue";
import ATag from "./Atoms/ATag/index.vue";
import APageTitle from "./Atoms/APageTitle/index.vue";
import ACharacter from "./Atoms/ACharacter/index.vue";
import ANavi from "./Atoms/ANavi/index.vue";
import ATitle from "./Atoms/ATitle/index.vue";
import AEditContent from "./Atoms/AEditContent/index.vue";
import ABlankThumb from "./Atoms/ABlankThumb/index.vue";
import ABanner from "./Atoms/ABanner/index.vue";
import AText from "./Atoms/AText/index.vue";

// Molecules
import MArticleList from "./Molecules/MArticleList/index.vue";
import MArticleRankingList from "./Molecules/MArticleRankingList/index.vue"
import MStaffList from "./Molecules/MStaffList/index.vue";
import MCompanyList from "./Molecules/MCompanyList/index.vue";
import MBannerList from "./Molecules/MBannerList/index.vue";
import MTagList from "./Molecules/MTagList/index.vue";
import MNaviAdjacentPost from "./Molecules/MNaviAdjacentPost/index.vue";
import MArticleSlider from "./Molecules/MArticleSlider/index.vue";

// Organisms
import OHeader from "./Organisms/OHeader/index.vue";
import OFooter from "./Organisms/OFooter/index.vue";
import OStaff from "./Organisms/OStaff/index.vue";
import OArticle from "./Organisms/OArticle/index.vue";

// Layouts
import LMain from "./Layouts/LMain/index.vue";

export default {
  VcButton,
  AButton,
  AArticleSmall,
  AStaff,
  AArticle,
  ACompany,
  APagination,
  ABreadcrumb,
  ATag,
  APageTitle,
  ACharacter,
  ANavi,
  ATitle,
  AEditContent,
  ABlankThumb,
  ABanner,
  AText,
  MArticleList,
  MArticleRankingList,
  MStaffList,
  MCompanyList,
  MBannerList,
  MTagList,
  MNaviAdjacentPost,
  MArticleSlider,
  OHeader,
  OFooter,
  OStaff,
  OArticle,
  LMain,
};
